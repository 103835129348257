<template>
  <v-navigation-drawer permanent color="#272727" style="color:#fff" id="core-navigation-drawer" app
    :right="$vuetify.rtl" :dark="'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular">
          <v-row align="center" justify="center">
            <v-col align="center" justify="center" cols="12" md="6">
              <h4 style="color:#fff">CMS </h4>
            </v-col>
            <v-col class="text-center">
              <!-- <img width="31px" src="../../assets/project-management.svg" /> -->
            </v-col>

          </v-row>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    {{changeLang()}}
    <!-- <v-divider class="mb-1" /> -->

    <v-list-item two-line>


      <v-list-item-avatar>
        <img
          src="https://cdn1.vectorstock.com/i/1000x1000/51/05/male-profile-avatar-with-brown-hair-vector-12055105.jpg" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title style="color:#fff">{{ $store.state.AdminInfo.name }}</v-list-item-title>
        <v-list-item-subtitle style="color:#fff">{{
         $store.state.AdminInfo.name
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-divider light style="color#fff" /> -->

    <v-list dense nav>
      <template v-if="uid === 28">
        <v-list-item style="color:#fff !important" v-for="item in items2" :key="item.title" link :to="item.to">
          <v-list-item-icon>
            <v-icon style="color:#fff !important">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item style="color:#fff !important" v-for="item in items" :key="item.title" link :to="item.to">
          <v-list-item-icon>
            <v-icon style="color:#fff !important">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios";
  export default {

    data() {
      return {
        uid: 0,
        items2: [
          
          {
            title: 'المتقدمين',
            icon: 'fas fa-newspaper',
            to: "/applicant"
          },

          {
            title: 'الاحصاءات',
            icon: 'fas fa-newspaper',
            to: "/stats"
          }

        ],

        items: [
          
          // {
          //   title: 'Dashboard',
          //   icon: 'mdi-view-dashboard',
          //   to: "/admin/statistics"
          // },
          {
            title: 'المتقدمين',
            icon: 'fas fa-newspaper',
            to: "/applicant"
          }

// {
//             title: 'qurans',
//             icon: 'fas fa-newspaper',
//             to: "/admin/qurans"
//           },
      
          // {
          //   title: 'About',
          //   icon: 'fas fa-user-secret',
          //   to: "/admin/about"
          // },
          // {
          //   title: 'Gallery',
          //   icon: 'far fa-image',
          //   to: "/admin/Gallery"
          // },
          // {
          //   title: 'GalleryCats',
          //   icon: 'far fa-images',
          //   to: "/admin/GalleryCats"
          // },

         
          // {
          //   title: 'messages',
          //   icon: 'fas fa-envelope',
          //   to: "/admin/messages"
          // },
          // {
          //   title: 'cat_arts',
          //   icon: 'fas fa-newspaper',
          //   to: "/admin/artCats"
          // },
          // {
          //   title: 'books',
          //   icon: 'fas fa-newspaper',
          //   to: "/admin/books"
          // },
          // {
          //   title: 'videos',
          //   icon: 'fas fa-newspaper',
          //   to: "/admin/videos"
          // },
          // {
          //   title: 'libraryImage',
          //   icon: 'fas fa-newspaper',
          //   to: "/admin/libraryImage"
          // },
          // {
          //   title:'callUs',
          //   icon:'fas fa-newspaper',
          //   to:"/admin/callUs"
          // }

        ],
        right: null,
      }
    },
    created () {
      this.auth();
    },
    methods: {
      auth() {
        axios.get("/jops/checkAuth", {
        headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }
        ).then(c => {
          if (c.data.success) {
            this.uid = c.data.data
          }
        })
      },
      changeLang() {
        // this.items[0].title = this.$t("Drawer.statistics")
         this.items[0].title = 'المتقدمين'
      // this.items[1].title = this.$t("Drawer.qurans")
         
        // this.items[3].title = this.$t("Drawer.about")
        // this.items[2].title = this.$t("Drawer.Gallery")
          //  this.items[4].title = this.$t("Drawer.messages")
        // this.items[5].title = this.$t("Drawer.GalleryCats")
       
        // this.items[2].title = this.$t("Drawer.books")
        // // this.items[6].title = this.$t("Drawer.cat_arts")
     
        // this.items[3].title = this.$t("Drawer.videos")
        
        // this.items[4].title = this.$t("Drawer.libraryImage")

        // this.items[5].title = this.$t("Drawer.callUs")


      },
    },
  }
</script>